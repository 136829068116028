import React, { lazy, Suspense } from "react";
import { debounce } from "lodash";
import WithContentTop from "src/common/navbar-height/with-content-top.decorator.js";
import Cancelable from "react-disposable-decorator";
import { catchAsyncStacktrace } from "auto-trace";
import { connect } from "react-redux";

import styles from "./tax-form-preview.style.css";
import canopyUrls from "canopy-urls!sofe";
import {
  generateFormPdf,
  getCollectionsCaseCount,
  getCollectionsForms,
} from "src/tax-forms/tax-form.resource";
import { from } from "rxjs";
import { concatMap } from "rxjs/operators";
import { a } from "kremling";
import { UserTenantProps } from "cp-client-auth!sofe";
import { CpButton, CpTooltip } from "canopy-styleguide!sofe";
import CollectionsModal from "./collections-modal/collections-modal.component";
import { consumeCollectionCase } from "../resolution-cases/resolution-cases.actions.js";
import { notifyAnalytics } from "../resources/analytics.resource";

const SaveToCanopyFilesDropdown = lazy(() =>
  SystemJS.import("docs-ui").then((m) => m.saveToCanopyFiles())
);

@Cancelable
@UserTenantProps({
  waitForData: true,
  permissions: {
    hasChangeFilesAccess: "files_upload_move",
    hasClientFilesAccess: "files_client",
  },
})
@WithContentTop((props) => ({
  hasTopnavSecondary: false,
  clientMenuPossible: true,
}))
@connect((state) => ({
  resolutionCase: state.resolutionCase,
}))
export default class TaxFormHeader extends React.Component {
  constructor(props) {
    super(props);

    // TODO: Checking licenses is not the norm, but there is no permission that currently encompasses these.
    // We should add a permission that checks the use case and replace this
    const { licenses } = props.loggedInUser;
    // Notices, TR, and transcripts users should be able to print/download/save administrative forms,
    // but should have to consume a collections case for other forms
    const hasNotices = licenses.some((l) => l === "irsNotices");
    const hasTaxRes = licenses.some((l) => l === "taxResolution");
    const hasTranscripts = licenses.some((l) => l === "transcripts");

    this.state = {
      cases: null,
      showCollectionsModal: false,
      isCollectionsForm: null,
      hasCollectionsAdminLicense: hasNotices || hasTaxRes || hasTranscripts,
      isCollectionsFormsLoading: false,
    };

    this.scrollListener = debounce(() => {
      this.forceUpdate();
    }, 10);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollListener);

    this.getCollectionsCaseCount();
  }

  componentDidUpdate() {
    if (
      this.props.versionId &&
      this.props.revisionId &&
      this.state.isCollectionsForm === null
    ) {
      this.getFormsList();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollListener);
  }

  render() {
    const {
      uiFormsState,
      taxFormId,
      clientId,
      resolutionCaseId,
      formName,
      versionId,
      revisionId,
      contentTop,
      resolutionCase,
    } = this.props;
    const topToStickTo = 25;
    const topInt =
      window.pageYOffset > topToStickTo
        ? contentTop
        : topToStickTo - window.pageYOffset + contentTop;
    const rootStyles = {
      position: "fixed",
      left: "auto",
      width: uiFormsState.cardWidth,
      paddingBottom: window.pageYOffset > 25 ? "1.6rem" : "1.2rem",
      top: String(topInt) + "px",
    };

    if (window.pageYOffset < 25) {
      rootStyles.boxShadow = "inherit";
    }

    const {
      cases,
      showCollectionsModal,
      isCollectionsForm,
      hasCollectionsAdminLicense,
    } = this.state;
    const base = `${canopyUrls.getAPIUrl()}/clients/${clientId}/resolution_cases/${resolutionCaseId}`;
    const printLink = `${base}/tax-form-pdfs/${taxFormId}?version=${versionId}-${revisionId}&action=print`;
    const downloadLink = `${base}/tax-form-pdfs/${taxFormId}?version=${versionId}-${revisionId}&action=download`;

    const { collection_case_consumed } = resolutionCase;
    const hasLimitedCollections = cases?.qty_available >= 0;
    const showPaywall =
      hasLimitedCollections &&
      (isCollectionsForm || !hasCollectionsAdminLicense);
    const loadingPaywall = !cases || isCollectionsForm === null;

    return (
      <div style={rootStyles} className="cps-card__header cps-card">
        <span className="cps-subheader">{formName}</span>
        <span className={`${styles.remainingCases} cps-pull-right`}>
          {!loadingPaywall &&
            showPaywall &&
            (collection_case_consumed ? (
              <span className={styles.caseActivated}>Case activated</span>
            ) : (
              <span className="cp-mr-24 cps-wt-semibold cps-caption">
                REMAINING CASES: {`${cases.qty_available}`}
              </span>
            ))}
          <CollectionsModal
            showCollectionsModal={showCollectionsModal}
            available={cases?.qty_available}
            consumeCase={this.consumeCase}
            close={this.closeCollections}
          />
          {!loadingPaywall &&
            (!collection_case_consumed && showPaywall ? (
              this.disabledIconRow()
            ) : (
              <>
                {this.props.permissions.hasChangeFilesAccess &&
                  this.props.permissions.hasClientFilesAccess && (
                    <Suspense fallback={null}>
                      <SaveToCanopyFilesDropdown
                        clientId={parseInt(clientId)}
                        saveHandler={this.savePdf}
                        iconClass={styles.saveToCanopyFilesIcon}
                        fileOrigin="resolution_cases_form"
                      />
                    </Suspense>
                  )}
                <CpButton
                  icon="misc-printer"
                  aria-label="print tax form"
                  onClick={(e) => this.printDownloadForm(e, "print", printLink)}
                />
                <CpButton
                  icon="af-open-down"
                  aria-label="download tax form"
                  onClick={(e) =>
                    this.printDownloadForm(e, "download", downloadLink)
                  }
                />
              </>
            ))}
        </span>
        {window.pageYOffset > 25 ? null : (
          <div className={`${styles.taxFormHeaderSkirt}`} />
        )}
      </div>
    );
  }

  printDownloadForm = (e, action, link) => {
    const { taxFormId, formName, taxReturn, resolutionCaseId } = this.props;

    if (action === "print") {
      notifyAnalytics(
        `form.print`,
        { taxFormId, formName, resolutionCaseId },
        "practice_management",
        "resolution_cases"
      );
      if (!taxReturn) {
        const printWindow = window.open(link, formName, "width=800,height=800");
        printWindow.focus();
        printWindow.print();
      }
    } else if (action === "download") {
      notifyAnalytics(
        `form.download`,
        { taxFormId, formName, resolutionCaseId },
        "practice_management",
        "resolution_cases"
      );
      if (!taxReturn) {
        window.location = link;
      }
    }

    taxReturn && (e.preventDefault(), this.props.tempPrint(action));
  };

  savePdf = (folderId) => {
    const { taxFormId, formName, resolutionCaseId } = this.props;
    notifyAnalytics(
      `form.save_to_files`,
      { taxFormId, formName, resolutionCaseId },
      "practice_management",
      "resolution_cases"
    );

    return generateFormPdf(
      this.props.clientId,
      this.props.resolutionCaseId,
      this.props.taxFormId,
      this.props.versionId,
      this.props.revisionId
    ).pipe(
      concatMap((pdfFile) => {
        return from(this.createDoc(folderId, pdfFile));
      })
    );
  };

  disabledIconRow = () => {
    const { permissions } = this.props;

    return (
      <div className={a("cps-list-inline", styles.formIcons)}>
        <CpButton btnType="flat" onClick={this.openCollections}>
          Activate case
        </CpButton>
        {permissions.hasChangeFilesAccess &&
          permissions.hasClientFilesAccess && (
            <CpTooltip
              className="cp-ml-8"
              text="Activate case to save to Canopy"
            >
              <CpButton
                disabled
                icon="folder-c"
                aria-label="Activate case to save to Canopy"
              />
            </CpTooltip>
          )}
        <CpTooltip className="cp-ml-8" text="Activate case to print">
          <CpButton
            disabled
            icon="misc-printer"
            aria-label="Activate case to print"
          ></CpButton>
        </CpTooltip>
        <CpTooltip className="cp-ml-8" text="Activate case to download">
          <CpButton
            disabled
            icon="af-open-down"
            aria-label="Activate case to download"
          ></CpButton>
        </CpTooltip>
      </div>
    );
  };

  getCollectionsCaseCount = (callback) => {
    this.props.cancelWhenUnmounted(
      getCollectionsCaseCount().subscribe((cases) => {
        this.setState({ cases }, callback);
      }, catchAsyncStacktrace())
    );
  };

  getFormsList = () => {
    const { clientId, resolutionCaseId, versionId, revisionId } = this.props;
    const params = {
      clientId,
      resolutionCaseId,
      versionId,
      revisionId,
    };
    if (!this.state.isCollectionsFormsLoading) {
      this.setState({ isCollectionsFormsLoading: true });
      this.props.cancelWhenUnmounted(
        getCollectionsForms(params).subscribe(
          (groups) => {
            const formGroupings = groups.find(
              (group) => group.name === "Resolution Forms Tab"
            )?.groups;

            // Since the structure for `Federal` and `States` forms did not match
            // I had to handle them separately instead of looping through
            const federalCollectionsForms =
              formGroupings
                ?.find((group) => group.name === "Federal")
                ?.groups?.find((group) => group.name === "Collections")
                ?.forms || [];

            const stateCollectionsForms =
              formGroupings
                ?.find((group) => group.name === "States")
                ?.groups?.find((group) => group.name === "Collections")
                ?.groups?.reduce((statesForms, group) => {
                  return statesForms.concat(group.forms);
                }, []) || [];

            const collectionsForms = federalCollectionsForms
              .concat(stateCollectionsForms)
              .map((form) => form?.meta?.taxForm);

            this.setState({
              isCollectionsForm: collectionsForms.some((form) => {
                return form === this.props.taxFormId;
              }),
              isCollectionsFormsLoading: false,
            });
          },
          (error) => {
            this.setState({
              isCollectionsForm: false,
              isCollectionsFormsLoading: false,
            });
            return catchAsyncStacktrace(error);
          }
        )
      );
    }
  };

  // refetch the collection case count on modal open and close
  // in order to refresh the count after they purchase more cases in another tab.
  // Prevents users from having to refresh the page manually.
  openCollections = () => {
    // update before opening the modal so it doesn't udpate after it renders
    this.getCollectionsCaseCount(() => {
      this.setState({ showCollectionsModal: true });
    });
  };

  closeCollections = () => {
    // close the modal before fetching as to not update before it closes
    this.setState(
      { showCollectionsModal: false },
      this.getCollectionsCaseCount
    );
  };

  consumeCase = () => {
    const { clientId, resolutionCaseId } = this.props;

    const next = () => {
      this.setState({ showCollectionsModal: false });
    };

    this.props.dispatch(
      consumeCollectionCase({ clientId, resolutionCaseId, next })
    );
  };

  createDoc = (folderId, file) => {
    return new Promise((resolve, reject) => {
      SystemJS.import("docs-ui!sofe").then((docsUi) => {
        docsUi.loadUploadFilesHelper().then((filesHelper) => {
          filesHelper.uploadFilesAsync([file], {
            destinationFolder: { id: folderId },
            inbox: false,
            hidden: false,
            uploadedFilesCallback: (uploadedFiles) => {
              resolve(uploadedFiles[0]);
            },
          });
        });
      });
    });
  };
}
