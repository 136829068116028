import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { catchAsyncStacktrace } from "auto-trace";
import { partial, noop } from "lodash";
import Dropzone from "react-dropzone";
import AsyncDecorator from "async-decorator/rx6";

import { CpButton, CpLoader } from "canopy-styleguide!sofe";

import { deleteFile, getFileObject } from "src/resources/files.resource.js";
import CanopyInput from "./canopy-input.decorator.js";

const LinkedFileCard = React.lazy(() =>
  SystemJS.import("docs-ui!sofe").then((m) => m.LinkedFileCardFn())
);

const AddFileButton = lazy(async () => {
  const { AddFileButton } = await SystemJS.import("docs-ui");
  return AddFileButton();
});

@AsyncDecorator
@CanopyInput()
export default class FileUpload extends React.Component {
  static propTypes = {
    answer: PropTypes.array,
    clientId: PropTypes.string,
    question: PropTypes.object,
    updateAnswer: PropTypes.func,
    validate: PropTypes.func,
  };

  state = {
    file: null,
    localAnswer: [],
    showLoader: false,
    uploadFileFromHarddrive: false, // flag to tell if user used the local upload option instead of selecting canopy files
  };

  dropzoneRef = React.createRef();

  // Used here because the parent structure is rendering this component a bunch of times, unnecessarily
  shouldComponentUpdate = (nextProps, nextState) =>
    this.props.answer !== nextProps.answer || this.state !== nextState;

  componentDidUpdate(prevProps) {
    if (this.props.answer && prevProps.answer !== this.props.answer) {
      this.retrieveFileObject(this.props.answer[0] || this.props.answer);
    } else if (!this.props.answer && this.state.file) {
      this.setState({ file: null, showLoader: false });
    } // else is implicit here, we just do nothing
  }

  render() {
    const { showFileSelectionMenu, file } = this.state;

    return (
      <div>
        {file ? (
          <React.Suspense fallback={null}>
            <LinkedFileCard
              file={file}
              handleRemoveFile={partial(this.removeFile, file.id)}
            />
          </React.Suspense>
        ) : (
          <div style={{ position: "relative" }}>
            <Dropzone
              accept="application/pdf"
              className="dropzone cps-body"
              onClick={(evt) => evt.preventDefault()}
              multiple={false}
              onDrop={this.handleFileProvided}
              onFileDialogCancel={this.closeLinkFileOptions}
              ref={this.dropzoneRef}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} style={{ outline: "none" }}>
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
            {this.state.showLoader ? (
              <CpLoader />
            ) : (
              <CpButton
                btnType="primary"
                onClick={this.toggleFileSelectionDialog}
              >
                Upload PDF
              </CpButton>
            )}
            {showFileSelectionMenu && (
              <Suspense fallback={null}>
                <AddFileButton
                  attachFilesCallback={this.attachFile}
                  close={this.closeLinkFileOptions}
                  filePickerProps={{
                    // these options are listed here: https://code.canopy.ninja/front-end/red/docs-ui/blob/master/src/global-files/layouts/files-controller.component.js#L39
                    disableClients: !this.props.clientId,
                  }}
                  submitButtonText="Choose"
                  onUploadFile={this.handleUploadFile}
                />
              </Suspense>
            )}
          </div>
        )}
      </div>
    );
  }

  handleFileProvided = (acceptedFile) => {
    this.setState({ showLoader: true });
    SystemJS.import("docs-ui!sofe").then((docsUI) => {
      docsUI.loadUploadFilesHelper().then((filesHelper) => {
        filesHelper.uploadFilesAsync(acceptedFile, {
          destinationFolder: { id: `CON${this.props.clientId}` },
          inbox: false,
          uploadedFilesCallback: (response) => {
            const { id, name } = response[0];
            const answer = [id, name];

            this.props.validate(answer);
            this.props.updateAnswer(this.props.question, answer);

            this.setState({ showLoader: false, localAnswer: answer });
          },
        });
      });
    }, catchAsyncStacktrace());
  };

  handleUploadFile = () => {
    this.setState({ uploadFileFromHarddrive: true });
    this.dropzoneRef.current.open();
  };

  attachFile = (params) => {
    if (params.length) {
      const { id, name } = params[0];
      const answer = [id, name];

      this.props.validate(answer);
      this.props.updateAnswer(this.props.question, answer);
      this.setState({
        localAnswer: answer,
        file: params.length ? params[0] : null,
        showFileSelectionMenu: false,
      });
    }
  };

  toggleFileSelectionDialog = () => {
    this.setState((prevState) => ({
      showFileSelectionMenu: !prevState.showFileSelectionMenu,
    }));
  };

  closeLinkFileOptions = () => {
    this.setState({ showFileSelectionMenu: false });
  };

  removeFile = (fileId) => {
    // Did we upload a local file?
    if (this.state.uploadFileFromHarddrive) {
      // Yes - delete the file from S3
      this.props.cancelWhenUnmounted(
        deleteFile(fileId).subscribe(noop, catchAsyncStacktrace())
      );
    }
    this.props.validate(null);
    this.props.updateAnswer(this.props.question, null);
    this.setState({ file: null, localAnswer: [] });
  };

  retrieveFileObject = (fileId) => {
    this.setState({ showLoader: true });
    this.props.cancelWhenUnmounted(
      getFileObject(fileId).subscribe((file) => {
        //TODO: add some hooks for feedback to the user if something goes awry
        this.setState({
          file: file.id ? file : null,
          localAnswer: file.id && file.name ? [file.id, file.name] : [],
          showLoader: false,
        });
      }, catchAsyncStacktrace())
    );
  };
}
